import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalProviderService } from '../../../providers/global-provider.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from '../../../providers/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    submitted: boolean = false;
    passwordVisible: boolean = false;
    isRole:'';

    constructor(
        private formBuilder: FormBuilder,
        private globalProvider: GlobalProviderService,
        private toastr: ToastrService, // Toastr service injected
        private router: Router,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
        });
    }

    onSubmit() {
        if (this.loginForm.valid) {
            let formData = this.loginForm.value;

            this.globalProvider
                .httpPost('/user/login', formData)
                .then((data: any) => {
                    console.log('Submit Response--', data);
                    this.toastr.success('Login successful', 'Success'); // Show success message
                    const token = data?.token;
                    this.authService.setToken(token);
                    localStorage.setItem('userName',data.username)
                    this.isRole = data?.role
                    localStorage.setItem('Role',this.isRole)


                    this.router.navigate(['']);
                })
                .catch((err) => {
                    this.toastr.error(err.statusText, 'Error'); // Show error message
                    console.log('Submit error', err);
                });

            console.log(formData);
        } else {
            this.markFormGroupTouched(this.loginForm);
        }
    }

    markFormGroupTouched(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (control instanceof FormGroup) {
                this.markFormGroupTouched(control);
            } else {
                control.markAsTouched();
            }
        });
    }

    togglePasswordVisibility(passwordInput: HTMLInputElement) {
        this.passwordVisible = !this.passwordVisible;
        passwordInput.type = this.passwordVisible ? 'text' : 'password';
    }
}
