<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-end">
                    <div class="col-lg-8 col-md-12">
                        <div class="left">
                            <img src="assets/img/job-details1.png" alt="Details">
                            <h2>Product Designer</h2>
                            <ul>
                                <li><i class='bx bx-pie-chart-alt-2'></i> Sales & Marketing</li>
                                <li><i class='bx bx-time'></i> Posted date: 10 months ago</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="right">
                            <a class="cmn-btn" routerLink="/job-details">Apply Now <i class='bx bx-plus'></i></a>
                            <ul>
                                <li><a routerLink="/job-details"><i class='bx bx-heart'></i> Save</a></li>
                                <li><a routerLink="/job-details"><i class='bx bx-share-alt'></i> Share</a></li>
                                <li><a routerLink="/job-details"><i class='bx bxs-report'></i> Report</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Job Details -->
<div class="job-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="details-item">
                    <div class="details-inner">
                        <h3>Job Description</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    </div>

                    <div class="details-inner">
                        <h3>Our Company</h3>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here.</p>
                    </div>

                    <div class="details-inner">
                        <h3>Previous Interview</h3>

                        <div class="job-details-slider owl-theme owl-carousel">
                            <div class="slider-item">
                                <img src="assets/img/job-details1.jpg" alt="Details">

                                <div class="video-wrap">
                                    <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=88s" class="popup-youtube"><i class='bx bx-play'></i></a>
                                </div>
                            </div>

                            <div class="slider-item">
                                <img src="assets/img/job-details2.jpg" alt="Details">

                                <div class="video-wrap">
                                    <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=88s" class="popup-youtube"><i class='bx bx-play'></i></a>
                                </div>
                            </div>

                            <div class="slider-item">
                                <img src="assets/img/job-details3.jpg" alt="Details">

                                <div class="video-wrap">
                                    <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=88s" class="popup-youtube"><i class='bx bx-play'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="details-inner">
                        <h3>Job Responsibilities</h3>

                        <ul>
                            <li>
                                <i class='bx bx-message-square-check'></i>
                                They want to know that you weren’t lying on your resume about responsibilities.
                            </li>
                            <li>
                                <i class='bx bx-message-square-check'></i>
                                They want to check if your job responsibilities overlap with the role on offer.
                            </li>
                            <li>
                                <i class='bx bx-message-square-check'></i>
                                They want to put you at ease. Let’s face it. It’s an easy question.
                            </li>
                            <li>
                                <i class='bx bx-message-square-check'></i>
                                They want you to expand on the points that you included in your resume.
                            </li>

                            <li>
                                <i class='bx bx-message-square-check'></i>
                                They want to put you at ease. Let’s face it. It’s an easy question.
                            </li>
                            <li>
                                <i class='bx bx-message-square-check'></i>
                                They want to check if your job responsibilities overlap with the role on offer.
                            </li>
                            <li>
                                <i class='bx bx-message-square-check'></i>
                                Want you to expand on the points that you included in your resume.
                            </li>
                        </ul>
                    </div>

                    <div class="details-inner">
                        <h3>Job Responsibilities</h3>
                        
                        <ul>
                            <li>
                                <i class='bx bx-message-square-check'></i>
                                Ability to work under pressure
                            </li>
                            <li>
                                <i class='bx bx-message-square-check'></i>
                                Enterprise and entrepreneurial skills
                            </li>
                            <li>
                                <i class='bx bx-message-square-check'></i>
                                Negotiation and persuasion
                            </li>
                            <li>
                                <i class='bx bx-message-square-check'></i>
                                Understand where the other person is coming from so that you can both get what
                            </li>
                        </ul>
                    </div>
                    
                    <div class="details-inner">
                        <h3>Our Mission</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    </div>
                    
                    <div class="details-inner">
                        <h3>Our Vision</h3>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="widget-area">
                    <div class="job widget-item">
                        <h3>Find A Job</h3>

                        <form>
                            <label><i class="flaticon-send"></i></label>
                            <input type="text" class="form-control" placeholder="Email address">
                            <button type="submit" class="btn">Get Job Alert</button>
                        </form>
                    </div>

                    <div class="information widget-item">
                        <h3>Job Information</h3>

                        <ul>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Salary</h4>
                                <span>$1,500 - $2,000</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Category</h4>
                                <span>Sales & Marketing</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Location</h4>
                                <span>10th St Long Island, NY</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Offered Salary</h4>
                                <span>$2,000 - $2,500/month</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Posted</h4>
                                <span>7 Months Ago</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Experience</h4>
                                <span>2 Years</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Industry</h4>
                                <span>Development</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Level</h4>
                                <span>Employee</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Applied</h4>
                                <span>01</span>
                            </li>
                        </ul>
                    </div>

                    <div class="owner">
                        <div class="top">
                            <img src="assets/img/home-one/job4.png" alt="Owner">
                            <h3>Owner Info</h3>
                            <i class="flaticon-send"></i>
                            <span>Los Angeles, CA, USA</span>
                        </div>

                        <div class="information widget-item">
                            <ul>
                                <li>
                                    <img src="assets/img/job-details-icon.png" alt="Details">
                                    <h4>Phone</h4>
                                    <a href="tel:+34561232349987">+3456 123 234 9987</a>
                                </li>
                                <li>
                                    <img src="assets/img/job-details-icon.png" alt="Details">
                                    <h4>Email</h4>
                                    <a href="mailto:hello&#64;alzo.com">hello&#64;alzo.com</a>
                                </li>
                                <li>
                                    <img src="assets/img/job-details-icon.png" alt="Details">
                                    <h4>Location</h4>
                                    <a href="#" target="_blank">www.alzothemes.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Job Details -->

<!-- Job -->
<div class="job-details-related pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Related Jobs</h2>
        </div>

        <div class="employer-item">
            <a routerLink="/job-details">
                <img src="assets/img/home-one/job1.png" alt="Employer">
                <h3>Product Designer</h3>
                <ul>
                    <li><i class="flaticon-send"></i> Los Angeles, CS, USA</li>
                    <li>5 months ago</li>
                </ul>
                <p>We are Looking for a skilled Ul/UX designer amet conscu adiing elitsed do eusmod tempor</p>
                <span class="span-one">Accounting</span>
                <span class="span-two">FULL TIME</span>
            </a>
        </div>

        <div class="employer-item">
            <a routerLink="/job-details">
                <img src="assets/img/home-one/job2.png" alt="Employer">
                <h3>Sr. Shopify Developer</h3>
                <ul>
                    <li><i class="flaticon-send"></i> Houston, TX, USA</li>
                    <li>4 months ago</li>
                </ul>
                <p>Responsible for managing skilled Ul/UX designer amet conscu adiing elitsed do eusmod</p>
                <span class="span-one">Accounting</span>
                <span class="span-two two">FULL TIME</span>
            </a>
        </div>

        <div class="employer-item">
            <a routerLink="/job-details">
                <img src="assets/img/home-one/job3.png" alt="Employer">
                <h3>Tax Manager</h3>
                <ul>
                    <li><i class="flaticon-send"></i>Ho Chi Minh City, Vietnam</li>
                    <li>6 months ago</li>
                </ul>
                <p>International collaborative a skilled Ul/UX designer amet conscu adiing elitsed do eusmod</p>
                <span class="span-one two">Broardcasting</span>
                <span class="span-two three">FREELANCER</span>
            </a>
        </div>
    </div>
</div>
<!-- End Job -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->