import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../providers/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navbar-style-three',
  templateUrl: './navbar-style-three.component.html',
  styleUrls: ['./navbar-style-three.component.scss']
})
export class NavbarStyleThreeComponent implements OnInit {
  isLoggedIn: boolean = false;
  userName: string | undefined;
  isRole ='';
  dropdownVisible: boolean = false;



  constructor(
    private authService: AuthService,
    private router:Router,
    private toastr: ToastrService ,
  ) { }

  ngOnInit(): void {
    const token = this.authService.getToken();
    if (token) {
      this.userName = localStorage.getItem('userName'); // Implement this function to extract user information
      this.isRole=localStorage.getItem('Role');

    }
   
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
  }
  logout(): void {

    this.authService.removeToken();
    this.userName = undefined;
    this.toastr.success('Logged Out successfully!!', 'Success'); // Show success message
    this.router.navigate(['/login'])

  }

  private extractUserNameFromToken(token: string): string | undefined {
    const tokenParts = token.split('.');
    if (tokenParts.length === 3) {
      const payload = JSON.parse(atob(tokenParts[1]));
      return payload.email;
    }
    return undefined;
  }


}
