import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalProviderService } from '../../../providers/global-provider.service';
import { AuthService } from '../../../providers/auth.service';
import { HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;


  constructor(
    private fb: FormBuilder,
    private globalProvider: GlobalProviderService,
    private authService: AuthService,
    private toastr: ToastrService, // Toastr service injected

  ) { }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNo: ['', Validators.required],
      subject: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.contactForm.valid) {
      console.log(this.contactForm.value);
      this.globalProvider
      // .httpPost('/user/contact', this.contactForm.value)
      // .then((res) => console.log(res))
      // .catch((e) => console.log(e));

      this.globalProvider
      .httpPost('/user/contact', this.contactForm.value)
      .then((data: any) => {
          console.log('Submit Response--', data);
          this.toastr.success('Query Submitted Successfully', 'Success'); // Show success message
          this.contactForm.reset();
        
      })
      .catch((err) => {
          this.toastr.error(err.statusText, 'Error'); // Show error message
          console.log('Submit error', err);
      });
    } else {
      this.contactForm.markAllAsTouched();
      this.toastr.error("Enter Valid Details",'Error')
    }
  }

}
