import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {
    FormGroup,
    FormControl,
    Validators,
    FormBuilder,
} from '@angular/forms';
import { GlobalProviderService } from '../../../providers/global-provider.service';
import { AuthService } from '../../../providers/auth.service';
import { HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';


@Component({
  selector: 'app-request-candidate',
  templateUrl: './request-candidate.component.html',
  styleUrl: './request-candidate.component.scss'
})
export class RequestCandidateComponent implements OnInit {
  jobForm: FormGroup;
  public token = '';
  typeJobOptions = ['Full-Time', 'Part-Time', 'Contract'];
  qualificationOptions = [
      { value: 'CERTIFICATE', label: 'Certificate' },
      { value: 'DIPLOMA', label: 'Diploma' },
      { value: 'BACHELOR_DEGREE', label: 'Bachelor Degree' },
      { value: 'MASTER_DEGREE', label: 'Master Degree' },
      { value: 'NA', label: 'NA' },
  ];

  levelOptions = [
      { value: 'SENIOR', label: 'Senior' },
      { value: 'JUNIOR', label: 'Junior' },
      { value: 'ASSISTANCE', label: 'Assistance' },
      { value: 'LEAD', label: 'Lead' },
  ];

  constructor(
      private fb: FormBuilder,
      private globalProvider: GlobalProviderService,
      private authService: AuthService,
      private toastr: ToastrService, // Toastr service injected
      private router: Router
  ) {}

  ngOnInit(): void {
      this.jobForm = new FormGroup({
          company: new FormControl('', Validators.required),
          title: new FormControl('', Validators.required),
          typeJob: new FormControl('', Validators.required),
          category: new FormControl('', Validators.required),
          salary: new FormControl('', Validators.required),
          experience: new FormControl('0 - 1', Validators.required),
          qualification: new FormControl('CERTIFICATE', Validators.required),
          level: new FormControl('SENIOR', Validators.required),
      });
      const token = this.authService.getToken();
      if (token) {
          this.token = this.extractUserNameFromToken(token); // Implement this function to extract user information
      }
  }

  onValueChange(event): void {
      console.log('Selected value:', event.taget.value);
      // Handle the value change
  }

  private extractUserNameFromToken(token: string): string | undefined {
      const tokenParts = token.split('.');
      if (tokenParts.length === 3) {
          const payload = JSON.parse(atob(tokenParts[1]));
          return payload.email;
      }
      return undefined;
  }

  onSubmit(): void {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', ` ${token}`);
      // console.log('hii',headers)
      //     this.globalProvider
      //         .httpPost('/job', this.jobForm.value, headers)
      //         .then((res) => console.log(res))
      //         .catch((e) => console.log(e));
      if (this.jobForm.valid) {
          console.log(this.jobForm.value);

        //   this.globalProvider
        //       .httpPost('/job', this.jobForm.value, headers)
        //       .then((data: any) => {
        //           console.log('Submit Response--', data);
        //           this.toastr.success('Job Posted Successfully', 'Success'); // Show success message
        //           this.router.navigate(['/jobs']);

        //           this.jobForm.reset();
        //       })
        //       .catch((err) => {
        //           this.toastr.error(err.statusText, 'Error'); // Show error message
        //           console.log('Submit error', err);
        //       });
      } else {
          this.jobForm.markAllAsTouched();
          console.log('Form is not valid', this.jobForm);
      }
  }


}
