<!-- Apply For Job -->
<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Apply For Job</h2>
                    <!-- <ul>
                        <li>
                            <img src="assets/img/home-three/title-img-two.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Apply For Job</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<div class="post-job-area ptb-100">
    <div class="container">
      <form [formGroup]="jobForm" (ngSubmit)="onSubmit()">
        <div class="post-item">
          <div class="section-title">
            <h2>Apply For Job</h2>
          </div>
  
          <div class="row">
            <!-- <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>First Name:</label>
                <input type="text" class="form-control" formControlName="firstName" placeholder="Tom">
              </div>
            </div>  -->
  
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label> Name:</label>
                <input type="text" class="form-control" formControlName="name" placeholder="Henry">
                <div *ngIf="jobForm.get('name').invalid && (jobForm.get('name').dirty || jobForm.get('name').touched)">
                  <small class="text-danger small_text" *ngIf="jobForm.get('name').errors?.required">Name is required</small>
              </div>
              </div>
            </div>
  
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>Email:</label>
                <input type="email" class="form-control" formControlName="email" placeholder="example@gmail.com">
                <div *ngIf="jobForm.get('email').invalid && (jobForm.get('email').dirty || jobForm.get('email').touched)">
                  <small class="text-danger small_text" *ngIf="jobForm.get('email').errors?.required">Email is required</small>
                  <small class="text-danger small_text" *ngIf="jobForm.get('email').invalid">Enter Valid Email</small>
              </div>
              </div>
            </div>
  
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>Phone:</label>
                <input type="text" class="form-control" formControlName="phoneNumber" placeholder="+123-456-789" [maxlength]="10">
                <div *ngIf="jobForm.get('phoneNumber').invalid && (jobForm.get('phoneNumber').dirty || jobForm.get('phoneNumber').touched)">
                  <small class="text-danger small_text" *ngIf="jobForm.get('phoneNumber').errors?.required">Phone is required</small>
              </div>
              </div>
            </div>
  
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>Upload Resume:</label>
                <input type="file" class="form-control" (change)="onFileChange($event)" formControlName="resume" placeholder="Select Resume">
              </div>
            </div>
  
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>Company Name:</label>
                <input type="text" class="form-control" formControlName="companyName" placeholder="xyz company">
                <div *ngIf="jobForm.get('companyName').invalid && (jobForm.get('companyName').dirty || jobForm.get('companyName').touched)">
                  <small class="text-danger small_text" *ngIf="jobForm.get('companyName').errors?.required">company is required</small>
              </div>
              </div>
            </div>
  
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>Job Position:</label>
                <input type="text" class="form-control" formControlName="jobPosition" placeholder="Medical physicist">
                <div *ngIf="jobForm.get('jobPosition').invalid && (jobForm.get('jobPosition').dirty || jobForm.get('jobPosition').touched)">
                  <small class="text-danger small_text" *ngIf="jobForm.get('jobPosition').errors?.required">Position is required</small>
              </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12 d-none">
              <div class="form-group">
                <label>Job Id:</label>
                <input type="text" class="form-control"  formControlName="jobId"   readonly>
              </div>
            </div>
          </div>

          
  
          <button type="submit" class="btn">Apply For Job</button>
        </div>
      </form>
    </div>
  </div>
  <!-- End Apply For Job -->
  