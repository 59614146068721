<!-- Footer -->
<footer class="pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a class="logo" routerLink="/index.html"><img class="main_logo" src="assets/img/qmsLogo.png" alt="Logo"></a>
                        
                        <ul>
                            <li><span>Address: </span>2659 Autostrad St, London, UK</li>
                            <li><span>Message: </span><a href="mailto:hello&#64;alzo.com">hello&#64;alzo.com</a></li>
                            <li><span>Phone: </span><a href="tel:2151234567">215 - 123 - 4567</a></li>
                            <li><span>Open: </span>Mon - Fri / 9:00 AM - 6:00 PM</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-service">
                        <h3>Jobs List</h3>

                        <ul>
                            <li><a routerLink="/jobs">Accounting</a></li>
                            <li><a routerLink="/jobs">Teachers</a></li>
                            <li><a routerLink="/jobs">Customer Service</a></li>
                            <li><a routerLink="/jobs">Digital Marketing</a></li>
                            <li><a routerLink="/jobs">Web & Software Dev</a></li>
                            <li><a routerLink="/jobs">Science & Analytics</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="footer-item">
                    <div class="footer-service">
                        <h3>Useful Links</h3>

                        <ul>
                            <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                            <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                            <li><a routerLink="/jobs">Jobs</a></li>
                            <li><a routerLink="/candidates">Candidates</a></li>
                            <li><a routerLink="/blog">Blog</a></li>
                            <li><a routerLink="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-newsletter">
                        <h3>Newsletter</h3>
                        <p>Lorem ipsum dolor sit amet conscu adipiscing elit sed</p>
                        <form class="newsletter-form">
                            <input type="email" class="form-control" placeholder="Your email*" name="EMAIL" required autocomplete="off">
                            <button class="btn" type="submit"><i class="flaticon-send"></i></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer -->

<!-- Copyright -->
<div class="copyright-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="copyright-item">
                    <ul>
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin-square'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="copyright-item">
                    <p>© Alzo is Proudly Owned by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Copyright -->