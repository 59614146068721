<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Contact</h2>
                    <!-- <ul>
                        <li>
                            <img src="assets/img/home-three/title-img-two.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Contact</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Info -->
<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row" style="justify-content: center;">
          

            <div class="col-sm-6 col-lg-4">
                <div class="info-item">
                    <i class='bx bx-world'></i>
                    <h3>Our Office</h3>
                    <p>3950 SE 38th Loop, Ocala, FL 34480</p>
                    <ul>
                        <li><span>Mobile:</span> <a href="tel:+23456783456">(352) 414 – 5253</a></li>
                        <li><span>Email:</span> <a href="mailto:clientservices&#64;quickmedstaffing.com">clientservices&#64;quickmedstaffing.com</a></li>
                    </ul>
                </div>
            </div>

          
        </div>
    </div>
</div>
<!-- End Info -->

<!-- Contact -->
<div class="contact-form-area pb-5">
    <div class="container">
        <div class="form-item">
            <h2>Contact Us</h2>
            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" formControlName="name" class="form-control" required>
                            <div *ngIf="contactForm.get('name').invalid && (contactForm.get('name').dirty || contactForm.get('name').touched)">
                                <small class="text-danger small_text" *ngIf="contactForm.get('name').errors?.required">Name is required</small>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Email</label>
                            <input type="email" formControlName="email" class="form-control" required>
                            <div *ngIf="contactForm.get('email').invalid && (contactForm.get('email').dirty || contactForm.get('email').touched)">
                                <small class="text-danger small_text" *ngIf="contactForm.get('email').errors?.required">Email is required</small>
                                <small class="text-danger small_text" *ngIf="contactForm.get('email').invalid">Enter Valid Email</small>

                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Phone</label>
                            <input type="text" formControlName="phoneNo" required class="form-control" [maxlength]="10">
                            <div *ngIf="contactForm.get('phoneNo').invalid && (contactForm.get('phoneNo').dirty || contactForm.get('phoneNo').touched)">
                                <small class="text-danger small_text" *ngIf="contactForm.get('phoneNo').errors?.required">Phone is required</small>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Subject</label>
                            <input type="text" formControlName="subject" class="form-control" required>
                            <div *ngIf="contactForm.get('subject').invalid && (contactForm.get('subject').dirty || contactForm.get('subject').touched)">
                                <small class="text-danger small_text" *ngIf="contactForm.get('subject').errors?.required">Subject is required</small>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <label>Message</label>
                            <textarea formControlName="message" class="form-control" cols="30" rows="8" required></textarea>
                            <div *ngIf="contactForm.get('message').invalid && (contactForm.get('message').dirty || contactForm.get('message').touched)">
                                <small class="text-danger small_text" *ngIf="contactForm.get('message').errors?.required">Message is required</small>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <button type="submit" class="btn">Send Message</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="faq-bottom  pb-100">
    <h3>Want To Hire A Candidate?</h3>
    <a routerLink="/request-candidate">Request</a>
</div>
<!-- End Contact -->

<!-- End Contact -->

<!-- Map -->
<div class="map-area">
    <div class="container-fluid p-0">
      <iframe id="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3464.4789999999997!2d-82.08326568477292!3d29.14524498209612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e6a9d7d4c9c5d7%3A0x7d5d5d1d7d5d5d5d!2s3950%20SE%2038th%20Loop%2C%20Ocala%2C%20FL%2034480!5e0!3m2!1sen!2sus!4v1684524000000!5m2!1sen!2sus" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </div>
<!-- End Map -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->