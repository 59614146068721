<!-- Navbar -->
<div class="navbar-area fixed-top">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/qmsLogo.png" alt="Logo"></a>
    </div>
    
    <div class="main-nav three">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand"  routerLink="/"><img class="main_logo" src="assets/img/qmsLogo.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a href="javascript:void(0)" routerLink="/" class="nav-link dropdown-text-color">Home </a>

                        </li>

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a href="javascript:void(0)" class="nav-link dropdown-toggle">Users <i class='bx bx-chevron-down'></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/login" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Login</a></li>

                                        <li class="nav-item"><a routerLink="/register" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Register</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item">
                                    <a href="javascript:void(0)" class="nav-link dropdown-toggle">Employers <i class='bx bx-chevron-down'></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/employers" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Employers</a></li>

                                        <li class="nav-item"><a routerLink="/employer-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Employer Details</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a routerLink="/dashboard" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Dashboard</a></li>

                                <li class="nav-item"><a routerLink="/single-resume" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Single Resume</a></li>

                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>

                                <li class="nav-item"><a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & COnditions</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a></li> -->

                        <li class="nav-item">
                            <!-- <span class="tooltip-span">Hot</span> -->

                            <a href="javascript:void(0)" class="nav-link dropdown-toggle dropdown-text-color">Jobs <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <!-- <li class="nav-item"><a routerLink="/jobs" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Jobs</a></li>

                                <li class="nav-item"><a routerLink="/favourite-jobs" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Favourite Jobs</a></li>

                                <li class="nav-item"><a routerLink="/job-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Job Details</a></li> -->
                                <li class="nav-item"><a routerLink="/jobs" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Jobs</a></li>


                                
                                <li class="nav-item"><a routerLink="/apply-for-job" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Apply For Job</a></li>
                                <li class="nav-item"><a routerLink="/request-candidate" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Request Candidate</a></li>

                                <!-- <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Apply For Job</a></li> -->
                                <!-- *ngIf="isRole=='ADMIN'" -->
                                


                            </ul>
                        </li>

                        <li class="nav-item" *ngIf="isRole=='ADMIN'">
                            <!-- <span class="tooltip-span">Hot</span> -->

                            <a href="javascript:void(0)" class="nav-link dropdown-toggle dropdown-text-color">Admin <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                
                                <li class="nav-item"><a routerLink="/post-a-job" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" *ngIf="isRole=='ADMIN'">Post A Job</a></li>
                                
                                <li class="nav-item"><a routerLink="/candidates" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" *ngIf="isRole=='ADMIN'">View Candidates</a></li>
                            
                            </ul>
                        </li>

                        <!-- <li class="nav-item">
                            <span class="tooltip-span two">New</span>

                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Candidates <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/candidates" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Candidates</a></li>

                                <li class="nav-item"><a routerLink="/candidate-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Candidate Details</a></li>
                            </ul>
                        </li> -->

                        <li class="nav-item">
                            <a href="javascript:void(0)" routerLink="/faq" class="nav-link dropdown-toggle dropdown-text-color">FAQ</a>

                        </li>


                        <li class="nav-item"><a routerLink="/contact" class="nav-link dropdown-text-color" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>

                     <div class="side-nav" #noUser *ngIf="!userName">
                        <a class="job-right" routerLink="/login">Login/Register</a>
                    </div>
                
                    <div *ngIf="userName" class="side-nav">
                        <div class="user-icon-container" (click)="toggleDropdown()">
                          <i class="fa fa-user user-icon" aria-hidden="true"></i>
                          <span class="tooltip">{{ userName }}</span>
                          <div *ngIf="dropdownVisible" class="dropdown-menu">
                            <button class="dropdown-item" (click)="logout()">Logout</button>
                          </div>
                        </div>
                      </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar -->