import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalProviderService } from '../../../providers/global-provider.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from '../../../providers/auth.service';

@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.scss']
})
export class CandidatesComponent implements OnInit {
  candidates: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private globalProvider: GlobalProviderService,
    private toastr: ToastrService ,// Toastr service injected
    private router:Router,
    private authService: AuthService

  ) { }
 

  ngOnInit(): void {
    this.getCandidateDetails();
  }

  getCandidateDetails(){
    this.globalProvider.httpGet('/application').then((data: any) => {
      console.log('Submit Response--', data);
      // this.toastr.success('Login successful', 'Success'); // Show success message
      this.candidates = data;

    }).catch(err => {
      this.toastr.error(err.statusText, 'Error'); // Show error message
      console.log('Submit error', err);
    });

  }
  viewResume(candidate: any): void {
    console.log('Viewing resume for', candidate.name);
    
    if (candidate?.resume) {
      // Construct the full URL if the resume path is relative
      const baseUrl = 'http://3.95.227.132:3000'; // Replace with your server's base URL
      const resumeUrl = candidate.resume.startsWith('http') ? candidate.resume : `${baseUrl}/${candidate.resume}`;
      
      // Open the resume in a new tab
      window.open(resumeUrl, '_blank');
    } else {
      console.error('Resume URL is not available for this candidate');
    }
  }
  
}
