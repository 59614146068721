<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Post A Job</h2>
                    <!-- <ul>
                        <li>
                            <img src="assets/img/home-three/title-img-two.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Post A Job</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Post A Job -->
<div class="post-job-area ptb-100">
    <div class="container">
        <form [formGroup]="jobForm" (ngSubmit)="onSubmit()">
            <div class="post-item">
                <div class="section-title">
                    <h2>Post A Job</h2>
                </div>

                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Company Name:</label>
                            <input
                                type="text"
                                class="form-control"
                                formControlName="company"
                                placeholder="XYZ company"
                            />
                            <div *ngIf="jobForm.get('company').invalid && (jobForm.get('company').dirty || jobForm.get('company').touched)">
                                <small class="text-danger small_text" *ngIf="jobForm.get('company').errors?.required">Companyis required</small>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Job Title:</label>
                            <input
                                type="text"
                                class="form-control"
                                formControlName="title"
                                placeholder="Nurse"
                            />
                            <div *ngIf="jobForm.get('title').invalid && (jobForm.get('title').dirty || jobForm.get('title').touched)">
                                <small class="text-danger small_text" *ngIf="jobForm.get('title').errors?.required">Title is required</small>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Job Type:</label>
                            <select id="typeJob" formControlName="type">
                                <option *ngFor="let option of typeJobOptions" [value]="option">{{ option }}</option>
                              </select>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Job Category:</label>
                            <input
                                type="text"
                                class="form-control"
                                formControlName="category"
                                placeholder="Medical Assistance"
                            />
                            <div *ngIf="jobForm.get('category').invalid && (jobForm.get('category').dirty || jobForm.get('category').touched)">
                                <small class="text-danger small_text" *ngIf="jobForm.get('category').errors?.required">Category is required</small>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Job Salary:</label>
                            <input
                                type="text"
                                class="form-control"
                                formControlName="salary"
                                placeholder="500$ - 1000$"
                            />
                            <div *ngIf="jobForm.get('salary').invalid && (jobForm.get('salary').dirty || jobForm.get('salary').touched)">
                                <small class="text-danger small_text" *ngIf="jobForm.get('salary').errors?.required">Salary is required</small>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Job Qualification:</label>
                            <select class="form-control" formControlName="qualification">
                                <option *ngFor="let option of qualificationOptions" [value]="option.value">
                                  {{ option.label }}
                                </option>
                              </select>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Job Level:</label>
                            <select class="form-control" formControlName="level">
                                <option *ngFor="let option of levelOptions" [value]="option.value">
                                  {{ option.label }}
                                </option>
                              </select>
                        </div>
                    </div>
                </div>

                <button type="submit" class="btn">Post A Job</button>
            </div>
        </form>
    </div>
</div>

<!-- End Post A Job -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
