<!-- Footer -->
<footer class="footer-area three">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="footer-item three">
                    <div class="footer-logo">
                        <a class="logo" routerLink="/index.html"><img class="main_logo" src="assets/img/qmsLogo.png" alt="Logo"></a>
                        
                        <ul>
                            <li><span>Address: </span>3950 SE 38th Loop, Ocala, FL 34480</li>
                            <li><span>Email: </span><a href="mailto:clientservices&#64;quickmedstaffing.com">clientservices&#64;quickmedstaffing.com</a></li>
                            <li><span>Phone: </span><a href="tel:2151234567">(352) 414 – 5253</a></li>
                        </ul>
                    </div>
                </div>
            </div>

          
        </div>
    </div>
</footer>
<!-- End Footer -->

<!-- Copyright -->
<div class="copyright-area three">
    <div class="container">
        <div class="row align-items-center">
            
            <div class="col-lg-6 col-md-12">
                <div class="copyright-item">
                    <p>©2024 Quick Med Staffing. All Rights Reserved</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Copyright -->