<!-- Register -->
<div class="user-form-area">
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <div class="col-lg-6 p-0 col-md-12">
                <div class="user-img">
                    <img src="assets/img/resgister-bg.jpg" alt="User">
                </div>
            </div>

            <div class="col-lg-6 p-0 col-md-12">
                <div class="user-content">
                    <div class="top">
                        <h2>Register</h2>

                        <form  [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                            <div class="form-group ip-div">
                                <input type="text" class="form-control" placeholder="Name" formControlName="name">
                                <div *ngIf="registerForm.controls.name.touched && registerForm.controls.name.invalid" class="text-danger">
                                    <div *ngIf="registerForm.controls.name.errors.required">Name is required.</div>
                                  </div>
                            </div>

                            <div class="form-group ip-div">
                                <input type="email" class="form-control" placeholder="Email" formControlName="email">
                                <div *ngIf="registerForm.controls.email.touched && registerForm.controls.email.invalid" class="text-danger">
                                    <div *ngIf="registerForm.controls.email.errors.required">Email is required.</div>
                                    <div *ngIf="registerForm.controls.email.errors.email">Invalid email format.</div>
                                  </div>
                            </div>

                           

                            <div class="form-group ip-div">
                                <input type="password"   class="form-control" placeholder="Password" formControlName="password" (input)="validatePasswordConfirmation(passwordInput.value)">
                               
                                <div *ngIf="registerForm.controls.password.touched && registerForm.controls.password.invalid" class="text-danger">
                                    <div *ngIf="registerForm.controls.password.errors.required">Password is required.</div>
                                    <div *ngIf="registerForm.controls.password.errors.minlength">Password must be at least 6 characters long.</div>
                                  </div>
                            </div>

                            <div class="form-group" style="padding-right: 20px;">
                                <div class="d-flex">

                                <input type="password" #passwordInput class="form-control" placeholder="Confirm Password" (input)="validatePasswordConfirmation(passwordInput.value)">
                                <div class="toggle-password" (click)="togglePasswordVisibility(passwordInput)">
                                    <span><i class="fas" [ngClass]="passwordVisible ? 'fa-eye-slash' : 'fa-eye'" aria-hidden="true"></i></span>
                                  </div>
                                  </div>
                                  <div *ngIf="confirmPasswordError" class="text-danger">
                                    Passwords do not match.
                                </div>  
                            </div> 

                            <button type="submit" class="btn" style="width: 90%;" [disabled]="confirmPasswordError">Register Here</button>
                        </form>
                    </div>

                    <div class="end">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Register -->