<!-- Navbar -->
<app-navbar-style-one></app-navbar-style-one>
<!-- End Navbar -->

<!-- Banner -->
<div class="banner-area">
    <div class="banner-shape"><img src="assets/img/home-one/banner/shape-bottom.png" alt="Shape"></div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="banner-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <h1>We Put <span>YOU</span> first so you can put your <span>PATIENTS</span> first</h1>
                            <p>A Continuously Updated Job Board to Help You Find the Perfect Healthcare Position You Need</p>

                            <!-- <div class="banner-form-area">
                                <form>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label><i class='bx bx-search'></i></label>
                                                <input type="text" class="form-control" placeholder="Search Your Job">
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <select>
                                                    <option>All Categories</option>
                                                    <option>Medical Assistance</option>
                                                    <option>Therapist</option>
                                                    <option>Clerk</option>
                                                </select>	
                                            </div>
                                        </div>

                                        <div class="col-lg-4 col-md-12">
                                            <div class="form-group two">
                                                <label><i class='bx bx-location-plus'></i></label>
                                                <input type="text" class="form-control" placeholder="Location">
                                            </div>
                                        </div>
                                    </div>

                                    <button type="submit" class="btn">Search Job <i class='bx bx-search'></i></button>
                                </form>
                            </div> -->

                            
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="banner-img">
                    <img class="img-main" src="assets/img/home-one/banner/Banner4.jpeg" alt="Shape">
                    <img class="img-1" src="assets/img/home-one/banner/Banner-1.jpeg" alt="Shape">
                    <img class="img-2" src="assets/img/home-one/banner/Banner2.jpeg" alt="Shape">
                    <img  src="assets/img/home-one/banner/banner4.png" alt="Shape">
                    <img src="assets/img/home-one/banner/banner5.png" alt="Shape">
                    <img src="assets/img/home-one/banner/banner6.png" alt="Shape">
                    <img class="img-7" src="assets/img/home-one/banner/Banner-7.jpeg" alt="Shape">
                    <img src="assets/img/home-one/banner/banner8.png" alt="Shape">
                    <img class="img-9" src="assets/img/home-one/banner/Banner-9.jpeg" >
                    <img src="assets/img/home-one/banner/Banner-10.jpeg" alt="Shape">
                    <img src="assets/img/home-one/banner/banner11.png" alt="Shape">
                    <img class="img-12" src="assets/img/home-one/banner/Banner-12.jpeg" alt="Shape">
                    <img class="img-13" src="assets/img/home-one/banner/Banner-13.jpeg" alt="Shape">
                    <div class="inner">
                        <img src="assets/img/home-one/banner/Banner6.jpeg" alt="Shape">
                        <p>Simplifying Healthcare for a Better, Healthier Future</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Banner -->

<!-- Work -->
<section class="work-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Working Process</span>
            <h2>See How It Works</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-verify"></i>
                    <span>STEP 01</span>
                    <h3>Register An Account</h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-file"></i>
                    <span>STEP 02</span>
                    <h3>Search Your Job</h3>

                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>STEP 03</span>
                    <h3>Apply For Job</h3>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Work -->

<!-- Jobs -->
<section class="job-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="section-title">
                    <span class="sub-title">About Us</span>
                    <h2>Quick Med Staffing</h2>
                </div>
            </div>
        </div>

        <div id="container" class="row">
            <div class="details-item">
                <p class="about_us">
                    Quickmed Staffing aims to Empower Healthcare Excellence: Our
mission is to connect exceptional healthcare professionals with leading medical institutions,
ensuring the highest standards of patient care. Through strategic staffing solutions, we strive
to bridge the gap between talented healthcare professionals and the dynamic needs of
healthcare facilities, fostering a healthier and more resilient community. Committed to
integrity, quality, and collaboration, we aim to be the preferred partner in advancing
healthcare through the power of skilled and compassionate professionals. At Quick Med
Staffing, we will go beyond the scope of your requirements to ensure the best fit for your
healthcare setting, your staffing requirements are our top priority
                </p>
            </div>
        </div>

    </div>
</section>
<!-- End Jobs -->

<!-- End App -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->