import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalProviderService } from '../../../providers/global-provider.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from '../../../providers/auth.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
  jobs = [
    {
      id: 1,
      imageUrl: 'assets/images/employer1.jpg',
      title: 'Software Developer',
      location: 'New York, NY',
      postedDate: new Date('2023-05-01'),
      description: 'Develop and maintain software applications.',
      category: 'IT',
      type: 'Full-time'
    },
    {
      id: 2,
      imageUrl: 'assets/images/employer2.jpg',
      title: 'Graphic Designer',
      location: 'San Francisco, CA',
      postedDate: new Date('2023-04-15'),
      description: 'Design graphics for various projects.',
      category: 'Design',
      type: 'Part-time'
    },
    {
      id: 3,
      imageUrl: 'assets/images/employer3.jpg',
      title: 'Project Manager',
      location: 'Chicago, IL',
      postedDate: new Date('2023-05-10'),
      description: 'Manage project timelines and deliverables.',
      category: 'Management',
      type: 'Contract'
    }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private globalProvider: GlobalProviderService,
    private toastr: ToastrService ,// Toastr service injected
    private router:Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getCandidateDetails();
  }

  getCandidateDetails(){
    this.globalProvider.httpGet('/job').then((data: any) => {
      console.log('Submit Response--', data);
      // this.toastr.success('Login successful', 'Success'); // Show success message
      this.jobs = data;

    }).catch(err => {
      this.toastr.error(err.statusText, 'Error'); // Show error message
      console.log('Submit error', err);
    });

  }
  viewResume(candidate) {
    // Logic to view the resume
    console.log('Viewing resume for', candidate.name);
    // Open a modal or navigate to a new page to show the resume
    console.log('srcc',candidate.resume)
    window.open(candidate.resume, '_blank');
  }
  apply(jobId: number) {
    console.log('idd',jobId)
    this.router.navigate(['/apply-for-job', jobId]);
  }


}
