import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalProviderService } from '../../../providers/global-provider.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
    passwordVisible: boolean = false;

    registerForm: FormGroup;
    confirmPasswordError: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private globalProvider: GlobalProviderService,
        private router: Router,
        private toastr: ToastrService // Toastr service injected
    ) {}

    ngOnInit(): void {
        this.registerForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]],
        });
    }

    onSubmit() {
        if (this.registerForm.valid) {
            // Form is valid, make API call
            const formData = this.registerForm.value;
            console.log(formData, 'register data---');
            let userObj = {
                email: formData.email,
                password: formData.password,
                username: formData.name,
            };

            this.globalProvider
                .httpPost('/user/signup', userObj)
                .then((data: any) => {
                    console.log('Submit Response--', data);
                    this.toastr.success(
                        'Account registered successful',
                        'Success'
                    ); // Show success message

                    this.router.navigate(['/login']);
                })
                .catch((err) => {
                    this.toastr.error('Unable to create account', 'Error'); // Show error message

                    console.log('Submit error', err);
                });
        } else {
            this.markFormGroupTouched(this.registerForm);
        }
    }

    markFormGroupTouched(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (control instanceof FormGroup) {
                this.markFormGroupTouched(control);
            } else {
                control.markAsTouched();
            }
        });
    }
    passwordMatchValidator(formGroup: FormGroup) {
        const password = formGroup.get('password').value;
        const confirmPassword = formGroup.get('confirmPassword').value;
        console.log(
            'pppp',
            password,
            confirmPassword,
            password == confirmPassword
        );

        return password == confirmPassword ? null : { passwordMismatch: true };
    }

    togglePasswordVisibility(passwordInput: HTMLInputElement) {
        this.passwordVisible = !this.passwordVisible;
        passwordInput.type = this.passwordVisible ? 'text' : 'password';
    }
    validatePasswordConfirmation(confirmPassword: string) {
        const password = this.registerForm.get('password').value;
        this.confirmPasswordError = password !== confirmPassword;
    }
}
