import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalProviderService } from '../../../providers/global-provider.service';
import { HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-apply-for-job',
    templateUrl: './apply-for-job.component.html',
    styleUrls: ['./apply-for-job.component.scss'],
})
export class ApplyForJobComponent {
    public jobId: string;
    jobForm: FormGroup;
    selectedFile: File | null = null;
    public emailRgx =
        "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$";

    constructor(
        private globalProvider: GlobalProviderService,
        private route: ActivatedRoute,
        private toastr: ToastrService
    ) {
        this.jobId = this.route.snapshot.paramMap.get('jobId') || "";
        console.log('Job ID:', this.jobId);

        this.jobForm = new FormGroup({
            name: new FormControl('', Validators.required),
            email: new FormControl('', [
                Validators.required,
                Validators.pattern(this.emailRgx),
            ]),
            phoneNumber: new FormControl('', Validators.required),
            resume: new FormControl('', Validators.required),
            companyName: new FormControl('', Validators.required),
            jobPosition: new FormControl('', Validators.required),
            jobId: new FormControl(this.jobId),
        });
    }

    ngOnInit() {
        // Initialize any other required data here
    }

    onFileChange(event: Event) {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            this.selectedFile = input.files[0];
        }
    }

    onSubmit() {
        if (this.jobForm.valid && this.selectedFile) {
            const formData = new FormData();
            formData.append('name', this.jobForm.get('name')?.value);
            formData.append('email', this.jobForm.get('email')?.value);
            formData.append(
                'phoneNumber',
                this.jobForm.get('phoneNumber')?.value
            );
            formData.append('resume', this.selectedFile);
            // formData.append(
            //     'companyName',
            //     this.jobForm.get('companyName')?.value
            // );
            // formData.append(
            //     'jobPosition',
            //     this.jobForm.get('jobPosition')?.value
            // );
            formData.append('jobId', this.jobForm.get('jobId')?.value);

            const token = localStorage.getItem('token');

            // this.globalProvider
            //     .httpPost('/application', formData)
            //     .then((res) => console.log(res))
            //     .catch((e) => console.log(e));
            this.globalProvider
                .httpPost('/application', formData)
                .then((data: any) => {
                    console.log('Submit Response--', data);
                    this.toastr.success('Applied Successfully', 'Success'); // Show success message
                    this.jobForm.reset();
                })
                .catch((err) => {
                    this.toastr.error(err.statusText, 'Error'); // Show error message
                    console.log('Submit error', err);
                });
        } else {
            this.jobForm.markAllAsTouched();
            this.toastr.error('Enter Valid Details', 'Error');
            console.log('invalid---', this.jobForm);
        }
    }
}
