import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private isLoggedIn = false;
    private userName: string | undefined;
    public auth_token: string = '';

    constructor() {}

    setToken(token: string): void {
        localStorage.removeItem('token');
        this.auth_token = token;
        if (this.auth_token) {
            localStorage.setItem('token', this.auth_token);
        }
    }

    // Method to get the token from local storage
    getToken(): string | null {
        return localStorage.getItem('token');
    }

    // Method to remove the token from local storage
    removeToken(): void {
        localStorage.removeItem('token');
        localStorage.removeItem('userName')
    }
    getAuthorizationToken() {
        this.auth_token = localStorage.getItem('token');
        return this.auth_token ? this.auth_token : '';
    }

    checkIfLoggedIn() {
        const token = this.getAuthorizationToken();
        if (token && token.length > 0) {
            return true;
        }
        return false;
    }
}
