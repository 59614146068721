import {
    CanActivateFn,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../providers/auth.service';
import { ToastrService } from 'ngx-toastr'; // Import ToastrService

export const authGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const authService = inject(AuthService);
    const router = inject(Router);
    const toastr = inject(ToastrService);

    return new Promise<boolean>((resolve, reject) => {
        if (!authService.checkIfLoggedIn()) {
            // Not logged in, redirect to login page with the return URL
            toastr.info('You need to log in to access this page.');
            router.navigate(['login']);
            reject(false);
        } else {
            resolve(true);
        }
    });
};
