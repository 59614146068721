<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Candidates</h2>
                    <!-- <ul>
                        <li>
                            <img src="assets/img/home-three/title-img-two.png" alt="Image">
                            <a href="index.html">Home</a>
                        </li>
                        <li>
                            <span>/</span>
                        </li>
                        <li>
                            Candidates
                        </li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Showing -->
<!-- <div class="job-showing-area">
    <div class="container">
        <h4>Showing 1 - 8 of 11 results</h4>
        <div class="showing">
            <div class="row">
                <div class="col-sm-6 col-lg-6">
                    <div class="left">
                        <div class="form-group">
                            <select>
                                <option>Newest</option>
                                <option>Another option</option>
                                <option>A option</option>
                                <option>Potato</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-6">
                    <div class="right">
                        <ul>
                            <li>
                                <a href="#">
                                    <i class='bx bx-dots-horizontal'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bx-menu'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Showing -->


<div class="candidate-area pb-100 mt-3">
    <div class="container">
      <div class="row">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Job Position</th>
              <th>Company Name</th>
              <th>Resume</th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr >
                <td>Abhishek</td>
                <td>yevle</td>
                <td>abhigmail.com</td>
                <td>12343322343</td>
                <td>web developer</td>
                <td>rishab software</td>
                <td>
                  <button class="btn btn-primary btn-sm">View</button>
                  <button class="btn btn-danger btn-sm">Delete</button>
                </td>
              </tr> -->
            <tr *ngFor="let candidate of candidates">
              <td>{{ candidate?.name }}</td>
              <td>{{ candidate?.email }}</td>
              <td>{{ candidate?.phoneNumber }}</td>
              <td>{{ candidate?.job?.level }}</td>
              <td>{{ candidate?.job?.company}}</td>
              <td>
                <button class="btn btn-primary btn-sm" (click)="viewResume(candidate)">View</button>
            </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
  
  

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->