<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>FAQ</h2>
                    <!-- <ul>
                        <li>
                            <img src="assets/img/home-three/title-img-two.png" alt="Image">
                            <a href="index.html">Home</a>
                        </li>
                        <li>
                            <span>/</span>
                        </li>
                        <li>
                            FAQ
                        </li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- FAQ -->
<div class="faq-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="faq-content">
                    <h4 class="heading">Benefits</h4>
                    <ul class="accordion">
                        <li>
                            <a>What information will I receive about the benefits?</a>
                            <p>Quick Med Staffing Team will send you the Benefits Guide that summarizes all of
                                the plans and rates. You will also receive an enrollment form.</p>
                        </li>
                        <li>
                            <a>How often are insurance premiums deducted from paycheck?</a>
                            <p>Insurance premiums will be deducted weekly.</p>
                        </li>
                        <li>
                            <a>Does Quick Med Staffing offer a 401K?</a>
                            <p>At this time Quick Med Staffing doesn’t not offer 401K</p>
                        </li>
                        <h4 class="heading">Compliance</h4>
                        <li>
                            <a>How soon will I receive first day instructions?</a>
                            <p>Once the team receives approval, instructions are generally released by two prior
                                to your start day.</p>
                        </li>
                        <li>
                            <a>Are the modules/heath stream paid?</a>
                            <p>No, modules are health streams and are not paid generally. They are a part of
                                your compliance completion. In case any specific facility pays for it, we will let
                                you know.</p>
                        </li>
                        <li>
                            <a>Do I need to pay for lab work or background check?</a>
                            <p>We take care of the cost for the background check and lab work.</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="faq-img">
                    <img src="assets/img/faq.jpg" alt="FAQ">
                </div>
            </div>
        </div>

        <div class="faq-bottom">
            <h3>If you don't find your questions or need help</h3>
            <a routerLink="/contact">Contact Us</a>
        </div>
    </div>
</div>
<!-- End FAQ -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->