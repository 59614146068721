<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-end">
                    <div class="col-lg-8 col-md-12">
                        <div class="left">
                            <img src="assets/img/home-one/job3.png" alt="Details">
                            <h2>Tax Manager</h2>
                            <ul>
                                <li><i class='bx bx-pie-chart-alt-2'></i> Sales & Marketing</li>
                                <li><i class='bx bx-time'></i> New York, USA</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="right">
                            <a class="cmn-btn" routeLink="/employer-details">Add Review <i class='bx bx-plus'></i></a>
                            <ul>
                                <li><a routerLink="/employer-details"><i class='bx bx-heart'></i> Follow</a></li>
                                <li><a routerLink="/employer-details"><i class='bx bx-share-alt'></i> Share</a></li>
                                <li><a routerLink="/employer-details"><i class='bx bxs-report'></i> Report</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Job Details -->
<div class="job-details-area employer-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="details-item">
                    <div class="details-inner">
                        <h3>About Company</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    </div>

                    <div class="details-inner">
                        <h3>Job Responsibilities</h3>

                        <ul>
                            <li><i class='bx bx-message-square-check'></i> Ability to work under pressure</li>
                            <li><i class='bx bx-message-square-check'></i> Enterprise and entrepreneurial skills</li>
                            <li><i class='bx bx-message-square-check'></i> Negotiation and persuasion</li>
                            <li><i class='bx bx-message-square-check'></i> Understand where the other person is coming from so that you can both get what</li>
                        </ul>
                    </div>

                    <div class="details-inner">
                        <h3>Image Gallery</h3>

                        <div class="job-details-slider owl-theme owl-carousel">
                            <div class="slider-item">
                                <img src="assets/img/job-details1.jpg" alt="Details">
                            </div>

                            <div class="slider-item">
                                <img src="assets/img/job-details2.jpg" alt="Details">
                            </div>

                            <div class="slider-item">
                                <img src="assets/img/job-details3.jpg" alt="Details">
                            </div>
                        </div>
                    </div>

                    <div class="details-inner">
                        <h3>Journey 2010-2024</h3>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here.</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure dignissimos quae rerum in autem, quos nisi ab minima molestiae ad enim, incidunt culpa totam nesciunt. Eum reprehenderit quidem doloribus suscipit.</p>
                    </div>

                    <div class="client-wrap">
                        <h2>(02) Reviews</h2>

                        <div class="client">
                            <img src="assets/img/candidate-details-client1.png" alt="Candidate Details">
                            <h3>Devit Killer</h3>
                            <span>January 12, 2024</span>
                            <p>Software hack from an initial feasibility study, continuing through l implna business you have to be But we know there's a better</p>
                            <ul>
                                <li><span>05</span></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                            </ul>
                        </div>

                        <div class="client">
                            <img src="assets/img/candidate-details-client2.png" alt="Candidate Details">
                            <h3>Jumar Kenton</h3>
                            <span>March 15, 2024</span>
                            <p>Software hack from an initial feasibility study, continuing through l implna business you have to be But we know there's a better</p>
                            <ul>
                                <li><span>05</span></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                            </ul>
                        </div>
                    </div>
                    
                    <div class="review">
                        <h3>Add Review</h3>
                        <span>Your rating for this listing</span>
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>

                        <form>
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck59">
                                <label class="form-check-label" for="exampleCheck59">Save my name, email, and website in this browser for the next time I comment.</label>
                            </div>

                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input type="text" class="form-control" placeholder="Name*">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <i class='bx bx-mail-send'></i>
                                        <input type="email" class="form-control" placeholder="Email*">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <i class='bx bx-mail-send'></i>
                                        <textarea id="your-message" rows="10" class="form-control" placeholder="Write message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="btn">Submit A Review</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="widget-area"> 
                    <div class="information widget-item">
                        <h3>Company Information</h3>

                        <ul>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Team Size</h4>
                                <span>50 - 100 Members</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Location</h4>
                                <span>New York, USA</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Categories</h4>
                                <span>Accounting/Finance</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Since</h4>
                                <span>1999</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Views</h4>
                                <span>1008</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Experience</h4>
                                <span>2 Years</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Industry</h4>
                                <span>Development</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Level</h4>
                                <span>Employee</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Applied</h4>
                                <span>01</span>
                            </li>
                        </ul>
                    </div>

                    <div class="owner">
                        <div class="top">
                            <img src="assets/img/home-one/job4.png" alt="Owner">
                            <h3>Owner Info</h3>
                            <i class="flaticon-send"></i>
                            <span>Los Angeles, CA, USA</span>
                        </div>
                        
                        <div class="information widget-item">
                            <ul>
                                <li>
                                    <img src="assets/img/job-details-icon.png" alt="Details">
                                    <h4>Phone</h4>
                                    <a href="tel:+34561232349987">+3456 123 234 9987</a>
                                </li>
                                <li>
                                    <img src="assets/img/job-details-icon.png" alt="Details">
                                    <h4>Email</h4>
                                    <a href="mailto:hello&#64;alzo.com">hello&#64;alzo.com</a>
                                </li>
                                <li>
                                    <img src="assets/img/job-details-icon.png" alt="Details">
                                    <h4>Location</h4>
                                    <a href="#" target="_blank">www.alzothemes.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Job Details -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->