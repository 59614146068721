<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area two three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-end">
                    <div class="col-lg-8 col-md-12">
                        <div class="left two">
                            <img src="assets/img/candidate-details1.jpg" alt="Details">
                            <h2>Markin M. Doland</h2>
                            <ul>
                                <li><i class='bx bx-box'></i> UX/UI Designer</li>
                                <li><i class='bx bx-phone-call'></i> <a href="+567908234875">+567 908 234 875</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="right">
                            <ul>
                                <li><a routerLink="/candidate-details"><i class='bx bx-heart'></i>Save</a></li>
                                <li><a routerLink="/candidate-details"><i class='bx bx-share-alt'></i> Share</a></li>
                                <li><a routerLink="/candidate-details"><i class='bx bxs-report'></i> Report</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Candidate Details -->
<div class="person-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="details-item">
                    <div class="profile">
                        <h3>My Profile</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search.</p>
                    </div>

                    <div class="work bottom-item">
                        <h3>Work Experience</h3>

                        <ul>
                            <li><img src="assets/img/home-three/title-img.png" alt="Icon"> Path Technologies, Washington, DC, USA</li>
                            <li><span>04/2011 - 01/2012</span></li>
                        </ul>
                        <h4>Senior Software Engineer</h4>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
                    </div>

                    <div class="work bottom-item">
                        <ul>
                            <li><img src="assets/img/home-three/title-img.png" alt="Icon"> University Of Maryland</li>
                            <li><span>03/2014 - 03/2016</span></li>
                        </ul>
                        <h4>Professor</h4>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
                    </div>

                    <div class="work bottom-item bottom-item-last">
                        <ul>
                            <li><img src="assets/img/home-three/title-img.png" alt="Icon"> 3s Software Bangladesh</li>
                            <li><span>02/2015 - 02/2024</span></li>
                        </ul>
                        <h4>John Hopkins, Bangladesh</h4>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
                    </div>

                    <div class="work bottom-item">
                        <h3>Education</h3>
                        <ul>
                            <li><img src="assets/img/home-three/title-img.png" alt="Icon"> Path Technologies, Washington, DC, USA</li>
                        </ul>
                        <h4>MBA (2024-2019)</h4>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
                    </div>

                    <div class="work bottom-item">
                        <ul>
                            <li><img src="assets/img/home-three/title-img.png" alt="Icon"> School of Design at University of Michigan</li>
                        </ul>
                        <h4>BBA (2010-2014)</h4>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
                    </div>

                    <div class="work bottom-item bottom-item-last">
                        <ul>
                            <li><img src="assets/img/home-three/title-img.png" alt="Icon"> Design at Institute of Technology & Marketing</li>
                        </ul>
                        <h4>Section UX & UI design (2014 - 2024)</h4>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
                    </div>

                    <div class="skills">
                        <h3>Skills</h3>

                        <div class="skill-wrap">
                            <div class="skill">
                                <h3>HTML/CSS</h3>
                                <div class="skill-bar skill1 animate__slideInLeft animate__animated">
                                    <span class="skill-count1">56%</span>
                                </div>
                            </div>

                            <div class="skill">
                                <h3>WORDPRESS</h3>
                                <div class="skill-bar skill2 animate__slideInLeft animate__animated">
                                    <span class="skill-count2">80%</span>
                                </div>
                            </div>

                            <div class="skill">
                                <h3>PHOTOSHOP</h3>
                                <div class="skill-bar skill3 animate__slideInLeft animate__animated">
                                    <span class="skill-count3">90%</span>
                                </div>
                            </div>

                            <div class="skill">
                                <h3>PHP</h3>
                                <div class="skill-bar skill4 animate__slideInLeft animate__animated">
                                    <span class="skill-count4">70%</span>
                                </div>
                            </div>

                            <div class="skill">
                                <h3>TEAM MANAGEMENT</h3>
                                <div class="skill-bar skill5 animate__slideInLeft animate__animated">
                                    <span class="skill-count5">80%</span>
                                </div>
                            </div>

                            <div class="skill">
                                <h3>SERVICE OF QUALITY</h3>
                                <div class="skill-bar skill6 animate__slideInLeft animate__animated">
                                    <span class="skill-count6">90%</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="portfolio">
                        <h3>Portfolio</h3>

                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <div class="img"><img src="assets/img/portfolio1.jpg" alt="Portfolio"></div>
                            </div>

                            <div class="col-lg-4 col-md-12">
                                <div class="img"><img src="assets/img/portfolio2.jpg" alt="Portfolio"></div>
                            </div>

                            <div class="col-lg-4 col-md-12">
                                <div class="img"><img src="assets/img/portfolio3.jpg" alt="Portfolio"></div>
                            </div>
                        </div>
                    </div>

                    <div class="client">
                        <img src="assets/img/candidate-details-client1.png" alt="Candidate Details">
                        <h3>Devit Killer</h3>
                        <span>January 12, 2024</span>
                        <p>Software hack from an initial feasibility study, continuing through l implna business you have to be But we know there's a better</p>
                        <ul>
                            <li><span>05</span></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                    </div>

                    <div class="review">
                        <h3>Add Review</h3>
                        <span>Your rating for this listing</span>
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <form>
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck59">
                                <label class="form-check-label" for="exampleCheck59">Save my name, email, and website in this browser for the next time I comment.</label>
                            </div>

                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input type="text" class="form-control" placeholder="Name*">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <i class='bx bx-mail-send'></i>
                                        <input type="email" class="form-control" placeholder="Email*">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <i class='bx bx-mail-send'></i>
                                        <textarea id="your-message" rows="10" class="form-control" placeholder="Write message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="btn">Submit A Review</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="widget-area">
                    <div class="information widget-item">
                        <h3>Job Overview</h3>

                        <ul>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Salary:</h4>
                                <span>$1,500 - $2,000</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Experience:</h4>
                                <span>5 years</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Age:</h4>
                                <span>30-35</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Gender:</h4>
                                <span>Both</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Languages:</h4>
                                <span>Bangla, English, Arbi, Spanish</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Qualification:</h4>
                                <span>Certificate, Associate Degree</span>
                            </li>
                        </ul>
                    </div>

                    <div class="download widget-item">
                        <a routerLink="/">Download CV</a>
                        <a class="right" routerLink="/contact">Contact Me</a>
                    </div>

                    <div class="owner">
                        <div class="top">
                            <img src="assets/img/home-one/job4.png" alt="Owner">
                            <h3>Owner Info</h3>
                            <i class="flaticon-send"></i>
                            <span>Los Angeles, CA, USA</span>
                        </div>

                        <div class="information widget-item">
                            <ul>
                                <li>
                                    <img src="assets/img/job-details-icon.png" alt="Details">
                                    <h4>Phone</h4>
                                    <a href="tel:+34561232349987">+3456 123 234 9987</a>
                                </li>
                                <li>
                                    <img src="assets/img/job-details-icon.png" alt="Details">
                                    <h4>Email</h4>
                                    <a href="mailto:hello&#64;alzo.com">hello&#64;alzo.com</a>
                                </li>
                                <li>
                                    <img src="assets/img/job-details-icon.png" alt="Details">
                                    <h4>Location</h4>
                                    <a href="#" target="_blank">www.alzothemes.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="similar-candidates">
                        <h4>Similar Candidates</h4>
                    </div>

                    <div class="candidate-item">
                        <div class="left">
                            <h3><a routerLink="/candidate-details">Arielle Terry</a></h3>
                            <span>Web Developer</span>
                            <p>I’m IRanin dolor sit amet conscu adiing elitsed eusmod tempor</p>
                            <ul>
                                <li>Php</li>
                                <li>jQuery</li>
                                <li>WordPress</li>
                                <li>CSS3</li>
                                <li>HTML5</li>
                            </ul>
                            <div class="cmn-link">
                                <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="candidate-item">
                        <div class="left">
                            <h3><a routerLink="/candidate-details">Alexander Max</a></h3>
                            <span>Senior UX/UI Designer</span>
                            <p>I’m Max dolor sit amet conscu adiing elitsed eusmod tempor</p>
                            <ul>
                                <li>CSS</li>
                                <li>Creative</li>
                                <li>Photoshop</li>
                                <li>Illustrator</li>
                                <li>HTML5</li>
                            </ul>
                            <div class="cmn-link">
                                <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Candidate Details -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->