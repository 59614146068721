<!-- Login -->
<div class="user-form-area">
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <div class="col-lg-6 p-0 col-md-12">
                <div class="user-img">
                    <img src="assets/img/resgister-bg.jpg" alt="User">
                </div>
            </div>

            <div class="col-lg-6 p-0 col-md-12">
                <div class="user-content">
                    <div class="top">
                        <h2>Login</h2>
                        
                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                            <div class="form-group " style="width: 90%;">
                              <input type="email" class="form-control" placeholder="Email" formControlName="email" required>
                              <div *ngIf="loginForm.get('email').errors?.required && loginForm.get('email').touched" class="text-danger">
                                Email is required.
                              </div>
                              <div *ngIf="loginForm.get('email').errors?.email && loginForm.get('email').touched" class="text-danger">
                                Please enter a valid email address.
                              </div>
                            </div>
                          
                            <div class="form-group" style="padding-right: 20px;">
                                <div class="d-flex">
                                    <input #passwordInput type="password" class="form-control" placeholder="Password" formControlName="password" required>
                                    <div class="toggle-password" (click)="togglePasswordVisibility(passwordInput)">
                                      <span><i class="fas" [ngClass]="passwordVisible ? 'fa-eye-slash' : 'fa-eye'" aria-hidden="true"></i></span>
                                    </div>
                            </div>
                                <div *ngIf="loginForm.get('password').errors?.required && loginForm.get('password').touched" class="text-danger">
                                  Password is required.
                                </div>
                              </div>
                              
                          
                            <button type="submit" class="btn" style="width: 90%;">Login Here</button>
                          </form>
                          
                    </div>
                    <div style="display: flex;margin-bottom: 3rem;margin-top: 3rem;" >
                    <div class="col-12 col-sm-12  col-md-7 no_acc_div">
                        <p style="text-align: center;margin-left: 5px;" class="no_acc_text">Don't have an account yet?</p>

                    </div>
                    <div class="col-12 col-sm-12 col-md-4 p-b-20">
                        <button type="submit" routerLink="/register" class="register_btn">
                            Register</button>


                    </div>
                </div>

                    <div class="end">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login -->